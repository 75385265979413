// components
import Validation from '@/js/components/forms/validation';

class JobDetail {
  constructor() {
    this.dom = {
      formJobApplication: document.getElementById('job-application-form'),
    };

    this.components = {
      jobApplicationFormValidation: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.formJobApplication) {
      this.components.jobApplicationFormValidation = new Validation(this.dom.formJobApplication);
    }
  }
}

if (document.getElementById('page-jobs-detail')) {
  // eslint-disable-next-line no-new
  new JobDetail();
}
