import ActionHandler from '@/js/components/forms/action_handler';

export default class SearchForm extends ActionHandler {
  constructor(formEl) {
    super();

    this.dom = {
      formEl,
      inputs: [...formEl.querySelectorAll('input[name="search"]')],
    };

    this.props = {
      url: formEl.getAttribute('action') || '',
    };

    this.events = {
      onFormSubmit: this.onFormSubmit.bind(this),
      onInputChange: this.onInputChange.bind(this),
    };

    this.mount();
  }

  mount() {
    this.dom.formEl.addEventListener('submit', this.events.onFormSubmit);

    this.dom.inputs.forEach((input) => {
      input.addEventListener('change', this.events.onInputChange);
    });
  }

  onFormSubmit(e) {
    e.preventDefault();

    const inputWithValue = this.dom.inputs.find((input) => input.value !== '');

    if (inputWithValue) {
      this.submit(inputWithValue.value);
    }
  }

  onInputChange(e) {
    const { value } = e.currentTarget;

    this.submit(value);
  }

  submit(value) {
    if (value === '') {
      return;
    }

    const formData = new FormData();
    formData.append('Search', value);

    this.send({ url: this.props.url, formData, action: 'search' });
  }
}
