import Swiper, {
  Navigation, Pagination, EffectFade, Lazy, Autoplay,
} from 'swiper/core';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, EffectFade, Lazy, Autoplay]);

class Home {
  constructor() {
    this.dom = {
      swiperIntro: document.getElementById('swiper-intro'),
    };

    this.data = {
      swiperConfig: {
        loop: true,
        speed: 1500,
        autoplay: {
          delay: 3500,
        },
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: {
          checkInView: true,
          loadPrevNext: true,
        },
        effect: 'fade',
      },
    };

    this.components = {
      swiperInstance: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.swiperIntro) {
      this.components.swiperInstance = new Swiper(this.dom.swiperIntro, this.data.swiperConfig);
    }
  }
}

if (document.getElementById('page-home')) {
  // eslint-disable-next-line no-new
  new Home();
}
