document.addEventListener('DOMContentLoaded', () => {
  const navContainer = document.querySelector('.l-site-nav');
  const dropdownToggle = document.querySelector('.mega-dropdown');
  const tabLinks = document.querySelectorAll('#dropdown-first-level .nav-link');
  const tabContents = document.querySelectorAll('.tab-content .tab-pane');

  let isDropdownOpen = false;

  function openDropdown() {
    dropdownToggle.classList.add('open');
    isDropdownOpen = true;
  }

  function closeDropdown() {
    dropdownToggle.classList.remove('open');
    isDropdownOpen = false;
  }

  if (dropdownToggle) {
    dropdownToggle.addEventListener('mouseenter', () => {
      openDropdown();
    });

    dropdownToggle.addEventListener('mouseleave', () => {
      // eslint-disable-next-line no-use-before-define,no-restricted-globals
      if (!isMouseInNavigationContainer(event)) {
        closeDropdown();
      }
    });

    navContainer.addEventListener('mouseleave', () => {
      if (isDropdownOpen) {
        closeDropdown();
      }
    });
  }

  function isMouseInNavigationContainer(event) {
    const rect = navContainer.getBoundingClientRect();
    return (
      event.clientX >= rect.left
      && event.clientX <= rect.right
      && event.clientY >= rect.top
      && event.clientY <= rect.bottom
    );
  }

  tabLinks.forEach((tabLink) => {
    tabLink.addEventListener('mouseenter', () => {
      const targetTabHref = tabLink.getAttribute('data-target');
      tabContents.forEach((tabContent) => {
        if (tabContent.id === targetTabHref.substr(1)) {
          tabContent.classList.add('active');
        } else {
          tabContent.classList.remove('active');
        }
      });
    });
  });
});
