import Swiper, {
  Navigation, Pagination, EffectFade, Lazy, Autoplay,
} from 'swiper/core';

Swiper.use([Navigation, Pagination, EffectFade, Lazy, Autoplay]);

const swiperWidget = document.getElementById('widget-swiper');

if (swiperWidget) {
  // const slides = swiperWidget.querySelectorAll('.swiper-slide');
  // const slidesCount = slides.length;
  // console.log(slidesCount);
  // let loopEnabled = true;
  //
  // // Check if there are less than 3 slides
  // if (slidesCount < 3) {
  //   loopEnabled = false;
  // }
  // eslint-disable-next-line no-unused-vars
  const swiperInstance = new Swiper(swiperWidget, {
    loop: false,
    slidesPerView: 1,
    spaceBetween: 30,
    centeredSlides: false,
    speed: 1500,
    autoplay: {
      delay: 3500,
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1400: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: {
      checkInView: true,
      loadPrevNext: true,
    },
    effect: 'slide',
  });
}
