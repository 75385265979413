import ActionHandler from '@/js/components/forms/action_handler';

export default class Validation extends ActionHandler {
  /**
   * @param formEl {HTMLElement}
   */
  constructor(formEl) {
    super();
    this.dom = {
      formEl,
      fields: [],
      message: formEl.querySelector('.js-message'),
    };

    this.props = {
      url: formEl.getAttribute('action') || '',
    };

    this.data = {
      invalidClass: 'is-invalid',
      message: {
        text: null,
        class: null,
      },
      formValidationClass: 'was-validated',
      errors: null,
    };

    this.events = {
      onFormSubmit: this.onFormSubmit.bind(this),
      onValidatedInputChange: this.onValidatedInputChange.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.formEl) {
      this.dom.formEl.addEventListener('submit', this.events.onFormSubmit);
    }
  }

  /**
   * form has been submitted
   * @param e {Event}
   */
  onFormSubmit(e) {
    // stay on page
    e.preventDefault();

    // reset message state
    this.resetMessage();

    // set fields
    this.dom.fields = [...this.dom.formEl.querySelectorAll('[name]:not([type="hidden"])')];

    // set formData
    const formData = new FormData(this.dom.formEl);

    this.send({ url: this.props.url, formData });
  }

  /**
   * request was successful
   * @param response {Object}
   */
  onRequestSuccess(response) {
    this.data.errors = response.data.errors || [];
    this.parseMessage(response.data.message);
    // this.setMessage(); // uncomment to always show message (error or success)

    // unbind change events
    this.dom.fields.forEach((f) => f.removeEventListener('change', this.events.onValidatedInputChange));

    if (this.data.errors && Object.keys(this.data.errors).length > 0) {
      // form has errors
      this.handleErrors();
    } else {
      this.setMessage();
      // form was submitted successfully
      this.handleSuccess();
    }

    super.onRequestSuccess(response);
  }

  /**
   * parse message object
   * @param messageObj {Object}
   */
  parseMessage(messageObj) {
    switch (messageObj.type) {
      case 'error':
        this.data.message.class = 'alert-danger';
        break;
      case 'success':
        this.data.message.class = 'alert-success';
        break;
      default:
        this.data.message.class = null;
        break;
    }

    // set text
    if (messageObj.text) {
      this.data.message.text = messageObj.text;
    } else {
      this.data.message.text = null;
    }
  }

  // show message
  setMessage() {
    // if we have no message, exit fn
    if (!this.data.message.text) return;

    // apply classes
    if (this.data.message.class) {
      this.dom.message.classList.add(this.data.message.class);
    }

    // apply text
    this.dom.message.textContent = this.data.message.text;

    // show message
    this.dom.message.classList.remove('d-none');

    // scroll to message
    this.dom.message.scrollIntoView({ behavior: 'smooth' });
  }

  // empty message and hide it
  resetMessage() {
    // if we have no message element, exit fn
    if (!this.data.message) return;

    // reset class
    this.dom.message.classList.add('d-none');
    if (this.data.message.class) {
      this.dom.message.classList.remove(this.data.message.class);
    }
    // reset text
    this.dom.message.textContent = '';
  }

  /**
   * validated input has changed
   * @param e {Event}
   */
  onValidatedInputChange(e) {
    const field = e.target;

    // remove invalid state
    field.classList.remove(this.data.invalidClass);

    // unbind change event
    field.removeEventListener('change', this.events.onValidatedInputChange);
  }

  // form was submitted successfully
  handleSuccess() {
    // hide validation states
    this.dom.formEl.classList.remove(this.data.formValidationClass);

    // reset form
    this.dom.formEl.reset();
  }

  // form has errors
  handleErrors() {
    for (const [key, value] of Object.entries(this.data.errors)) {
      // get element that has an error
      const field = this.dom.fields.find((f) => f.name === key);

      if (field) {
        const invalidFeedback = field.parentNode.querySelector('.invalid-feedback');

        // set error class
        field.classList.add(this.data.invalidClass);

        // set error
        if (invalidFeedback) {
          invalidFeedback.innerHTML = value.toString();
        }

        // bind change event
        field.addEventListener('change', this.events.onValidatedInputChange);
      }
    }

    // show errors
    this.dom.formEl.classList.add(this.data.formValidationClass);
  }
}
