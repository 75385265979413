const detailsElement = document.querySelector('.readmore');

if (detailsElement) {
  const iconElement = document.querySelector('.readmore-icon');

  detailsElement.addEventListener('toggle', () => {
    if (detailsElement.open) {
      iconElement.classList.remove('bi-chevron-down');
      iconElement.classList.add('bi-chevron-up');
    } else {
      iconElement.classList.remove('bi-chevron-up');
      iconElement.classList.add('bi-chevron-down');
    }
  });
}
