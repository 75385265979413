import Swiper, {
  Navigation, Pagination, EffectFade, Lazy, Autoplay,
} from 'swiper/core';

Swiper.use([Navigation, Pagination, EffectFade, Lazy, Autoplay]);

class ProductDetail {
  constructor() {
    this.dom = {
      swiper: document.getElementById('product-swiper'),
    };

    this.data = {
      swiperConfig: {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 30,
        centeredSlides: false,
        speed: 1500,
        autoplay: {
          delay: 3500,
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1400: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: {
          checkInView: true,
          loadPrevNext: true,
        },
        effect: 'slide',
      },
    };

    this.components = {
      swiperInstance: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.swiper) {
      this.components.swiperInstance = new Swiper(this.dom.swiper, this.data.swiperConfig);
    }
    // this.data.swiperConfig.loop = this.data.swiperConfig.loop && this.data.swiperConfig.slidesPerView > 1;
  }
}

if (document.getElementById('page-product-detail')) {
  // eslint-disable-next-line no-new
  new ProductDetail();
}
