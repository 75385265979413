document.addEventListener('DOMContentLoaded', () => {
  const dynamicTitle = document.querySelectorAll('.dynamicTitle');

  dynamicTitle.forEach((title) => {
    const titleText = title.textContent.trim();
    const splitIndex = Math.max(titleText.lastIndexOf(' ', Math.floor(titleText.length / 2)), 0);
    const firstLine = titleText.substring(0, splitIndex);
    const secondLine = titleText.substring(splitIndex);

    // eslint-disable-next-line no-param-reassign
    title.innerHTML = `${firstLine}<br><span>${secondLine}</span>`;
  });
});
